import { AppHeaderConfigPayload } from 'admin-portal-shared-services';
import { t } from 'i18next';
import { Modules } from 'routes/Definition';

export const defaultAppHeaderConfig: AppHeaderConfigPayload = {
  pageTitle: ' ',
  breadcrumbConfig: {
    homePath: '',
    items: [],
  },
  vendorSelect: false,
  countrySelect: false,
  countryOptions: [],
};

export const getAppHeaderConfig: (pageName: Modules) => Promise<AppHeaderConfigPayload> = async (
  pageName
) => {
  const {
    homePath,
    commercialControlPath,
    deliveryListPath,
    deliveryDetailsPath,
    ordersPath,
    orderDetailsPath,
  } = await import('routes/Definition');

  const getItemsCommercialControl = () => [
    {
      isCurrentPage: true,
      path: '',
      label: t('commercialControl:moduleName'),
    },
  ];

  const getItemsDeliveryList = () => [
    {
      isCurrentPage: false,
      path: commercialControlPath,
      label: t('commercialControl:moduleName'),
    },
    {
      isCurrentPage: true,
      path: deliveryListPath,
      label: t('deliveryList:moduleName'),
    },
  ];

  const getItemsDeliveryDetails = () => [
    {
      isCurrentPage: false,
      path: commercialControlPath,
      label: t('commercialControl:moduleName'),
    },
    {
      isCurrentPage: false,
      path: deliveryListPath,
      label: t('deliveryList:moduleName'),
    },
    {
      isCurrentPage: true,
      path: deliveryDetailsPath,
      label: t('deliveryDetails:moduleName'),
    },
  ];

  const getItemsOrders = () => [
    {
      isCurrentPage: false,
      path: commercialControlPath,
      label: t('commercialControl:moduleName'),
    },
    {
      isCurrentPage: true,
      path: ordersPath,
      label: t('ordersPage:moduleName'),
    },
  ];

  const getItemsOrderDetails = () => [
    {
      isCurrentPage: false,
      path: commercialControlPath,
      label: t('commercialControl:moduleName'),
    },
    {
      isCurrentPage: false,
      path: ordersPath,
      label: t('ordersPage:moduleName'),
    },
    {
      isCurrentPage: true,
      path: orderDetailsPath,
      label: t('orderDetails:moduleName'),
    },
  ];

  switch (pageName) {
    case 'COMMERCIAL_CONTROL':
      return {
        pageTitle: t('commercialControl:moduleName'),
        breadcrumbConfig: {
          homePath,
          items: getItemsCommercialControl(),
        },
      };
    case 'DELIVERY_LIST':
      return {
        pageTitle: t('deliveryList:moduleName'),
        breadcrumbConfig: {
          homePath,
          items: getItemsDeliveryList(),
        },
      };
    case 'DELIVERY_DETAILS':
      return {
        pageTitle: t('delivery:moduleName'),
        breadcrumbConfig: {
          homePath,
          items: getItemsDeliveryDetails(),
        },
      };
    case 'ORDERS':
      return {
        pageTitle: t('ordersPage:moduleName'),
        breadcrumbConfig: {
          homePath,
          items: getItemsOrders(),
        },
      };
    case 'ORDER_DETAILS':
      return {
        pageTitle: t('orderDetails:moduleName'),
        breadcrumbConfig: {
          homePath,
          items: getItemsOrderDetails(),
        },
      };
  }
};
