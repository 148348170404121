import { IDeliveryDetails as IDeliveryDetailsApi, IPostDeliveryDetailsResponse } from '@api';
import { DeliveryEnumStatus } from 'enums/lastMile';

export const deliveryDetailsDefault: IDeliveryDetailsApi = {
  id: '',
  distributionCenterId: '',
  accountId: '',
  accountName: '',
  accountAddress: '',
  accountCity: '',
  accountState: '',
  accountZipcode: '',
  driverName: '',
  truckPlate: '',
  estimatedTimeOfArrival: '',
  finishedAt: '',
  orderAmount: 0.0,
  progressBars: [],
  status: {
    value: DeliveryEnumStatus.NOT_STARTED,
    description: '',
    color: 'red',
    timestamp: '',
    reason: '',
  },
  updates: [],
  products: [],
};

export const deliveryDetailsTempMock: IPostDeliveryDetailsResponse = {
  id: '20240628_123202PaulaGomes',
  vendorId: '58870cbc-7809-4e18-ba59-986b4992c842',
  distributionCenter: {
    id: '0966',
    externalId: '0966',
    displayId: '0966',
    name: 'CDD Catalao',
    tradeName: 'CDD Catalao',
    timezone: 'America/Sao_Paulo',
  },
  account: {
    accountId: '85281fac-3ef2-449c-94d7-284450434d1c',
    customerAccountId: '00623534000343',
    displayName: 'Bar Beedle Bar Beedle Bar Beedle Bar Beedle Bar Beedle',
  },
  accountDeliveryAddress: {
    address: 'Av. Reg. Feijó, 1739 - Jardim Analia Franco',
    city: 'Davis',
    state: 'Piauí',
    zipcode: '16916649',
    latitude: -29.222,
    longitude: -51.1908,
  },
  chain: {
    id: 'chain_id',
    name: 'chain_namechain_namechain_namechain_namechain_name',
  },
  estimatedTimeOfArrival: '2024-06-28T16:39:24.574Z',
  driverName: 'Fabrício Nogueira Filho',
  truckPlate: 'NRO9265',
  total: 1310,
  progressBars: [
    { value: 100, color: 'green' },
    { value: 100, color: 'red' },
    { value: 50, color: 'red' },
  ],
  updates: [
    {
      value: DeliveryEnumStatus.ON_THE_WAY,
      description: ` está a caminho da loja.`,
      color: 'green',
      timestamp: '2024-06-28T09:00:00.000Z',
      reason: '',
    },
    {
      value: DeliveryEnumStatus.DELIVERY_STARTED,
      description: ` está na loja.`,
      color: 'green',
      timestamp: '2024-06-28T10:00:00.000Z',
      reason: '',
    },
    {
      value: DeliveryEnumStatus.WAITING_MODULATION,
      description: 'Algo deu errado. Estamos trabalhando em uma solução.',
      color: 'red',
      timestamp: '2024-06-28T11:00:00.000Z',
      reason: 'There are no products available',
    },
    {
      value: DeliveryEnumStatus.IN_TREATMENT,
      description: 'Algo deu errado. Estamos trabalhando em uma solução.',
      color: 'red',
      timestamp: '2024-06-28T12:00:00.000Z',
      reason: '',
    },
    {
      value: DeliveryEnumStatus.RESCHEDULED,
      description: 'A entrega foi reagendada para mais tarde hoje.',
      color: 'red',
      timestamp: '2024-06-28T13:00:00.000Z',
      reason: '',
    },
    {
      value: DeliveryEnumStatus.CONCLUDED,
      description: 'Entregue.',
      color: 'green',
      timestamp: '2024-06-28T14:00:00.000Z',
      reason: '',
    },
    {
      value: DeliveryEnumStatus.PARTIAL_DELIVERY,
      description: 'Entrega parcial.',
      color: 'red',
      timestamp: '2024-06-28T15:00:00.000Z',
      reason: '',
    },
  ],
  items: [
    {
      sku: '0004293',
      itemImage: 'https://cms-prod.global.ssl.fastly.net/media/09-05-2023-File-08d245dd.png',
      itemName: 'Dddaroma Natural De Creme A-58948 Mane-teste - 148251',
      quantity: 10,
      price: 10,
      total: 100,
      itemPlatformId: 'V0ljTXZIZ0pUaGk2V1poclNaTElRZz09OzAwMDAwMDAwMDAwMDE0ODI1MQ==',
      container: {
        name: 'Dddaroma Natural De Creme A-58948 Mane',
        unitOfMeasurement: 'KG',
        returnable: false,
      },
      packageItem: {
        name: '1x12',
        itemCount: 12,
      },
    },
    {
      sku: '0004293',
      itemImage: 'https://cms-prod.global.ssl.fastly.net/media/09-05-2023-File-08d245dd.png',
      itemName: 'Barcelo   Dark Series- Test - 0',
      quantity: 10,
      price: 10,
      total: 100,
      itemPlatformId: 'V0ljTXZIZ0pUaGk2V1poclNaTElRZz09Oy1SMDAyMjU4',
      container: {
        name: 'Long-neck',
        itemSize: 355,
        unitOfMeasurement: 'teste',
        returnable: true,
      },
      packageItem: {
        name: '2x12',
        itemCount: 12,
      },
    },
    {
      sku: '0004293',
      itemImage: 'https://cms-prod.global.ssl.fastly.net/media/09-05-2023-File-08d245dd.png',
      itemName: 'Skol Extra',
      quantity: 10,
      price: 10,
      total: 100,
      itemPlatformId: 'V0ljTXZIZ0pUaGk2V1poclNaTElRZz09Oy1JMDAxOTkw',
      container: {
        name: 'Botella Lpc',
        itemSize: 1750,
        unitOfMeasurement: 'ML',
        returnable: true,
      },
      packageItem: {
        name: 'Box',
        itemCount: 1,
      },
    },
    {
      sku: '0004293',
      itemImage: 'https://cms-prod.global.ssl.fastly.net/media/09-05-2023-File-08d245dd.png',
      itemName: 'Barril Aluminio 5l - Unidade',
      quantity: 10,
      price: 10,
      total: 100,
      itemPlatformId: 'V0ljTXZIZ0pUaGk2V1poclNaTElRZz09O0JFRURfRU1QVFkwNA==',
      container: {
        name: 'Barril Aluminio 5l',
        itemSize: 5000,
        unitOfMeasurement: 'ML',
        returnable: true,
      },
      packageItem: {
        name: 'Un',
        itemCount: 1,
      },
    },
    {
      sku: '0004293',
      itemImage: 'https://cms-prod.global.ssl.fastly.net/media/09-05-2023-File-08d245dd.png',
      itemName: 'Engradado Plastico 1x24 Caixa',
      quantity: 10,
      price: 10,
      total: 100,
      itemPlatformId: 'V0ljTXZIZ0pUaGk2V1poclNaTElRZz09O0JFRURfRU1QVFkwMw==',
      container: {
        name: 'Engradado Plastico 1x24',
        itemSize: 1,
        unitOfMeasurement: 'UN',
        returnable: true,
      },
      packageItem: {
        name: 'Un',
        itemCount: 1,
      },
    },
    {
      sku: '0004293',
      itemImage: 'https://cms-prod.global.ssl.fastly.net/media/09-05-2023-File-08d245dd.png',
      itemName: 'Garrafa Ambar 1000ml - Unidade',
      quantity: 10,
      price: 10,
      total: 100,
      itemPlatformId: 'V0ljTXZIZ0pUaGk2V1poclNaTElRZz09O0JFRURfRU1QVFkwMg==',
      container: {
        name: 'Garrafa De Ambar 1000ml',
        itemSize: 1000,
        unitOfMeasurement: 'ML',
        returnable: true,
      },
      packageItem: {
        name: 'Un',
        itemCount: 1,
      },
    },
    {
      sku: '0004293',
      itemImage: 'https://cms-prod.global.ssl.fastly.net/media/09-05-2023-File-08d245dd.png',
      itemName: 'Garrafa Ambar 600ml - Unidade',
      quantity: 10,
      price: 10,
      total: 100,
      itemPlatformId: 'V0ljTXZIZ0pUaGk2V1poclNaTElRZz09O0JFRURfRU1QVFkwMQ==',
      container: {
        name: 'Garrafa De Ambar 600ml',
        itemSize: 600,
        unitOfMeasurement: 'ML',
        returnable: true,
      },
      packageItem: {
        name: 'Un',
        itemCount: 1,
      },
    },
    {
      sku: '0004293',
      itemImage: 'https://cms-prod.global.ssl.fastly.net/media/09-05-2023-File-08d245dd.png',
      itemName: 'Choop Colorado Especial 5l',
      quantity: 10,
      price: 10,
      total: 100,
      itemPlatformId: 'V0ljTXZIZ0pUaGk2V1poclNaTElRZz09O0JFRURfMDQ=',
      container: {
        name: 'Barril De Aluminio 5l',
        itemSize: 5000,
        unitOfMeasurement: 'ML',
        returnable: true,
      },
      packageItem: {
        name: 'Un',
        itemCount: 1,
      },
    },
    {
      sku: '0004293',
      itemImage: 'https://cms-prod.global.ssl.fastly.net/media/09-05-2023-File-08d245dd.png',
      itemName: 'Brahma Duplo Malte 1x24 600ml',
      quantity: 10,
      price: 10,
      total: 100,
      itemPlatformId: 'V0ljTXZIZ0pUaGk2V1poclNaTElRZz09O0JFRURfMDM=',
      container: {
        name: 'Garrafa De Ambar 600ml',
        itemSize: 600,
        unitOfMeasurement: 'ML',
        returnable: true,
      },
      packageItem: {
        name: 'Cx',
        itemCount: 24,
      },
    },
    {
      sku: '0004293',
      itemImage: 'https://cms-prod.global.ssl.fastly.net/media/09-05-2023-File-08d245dd.png',
      itemName: 'Gatorade 500ml - Unidade',
      quantity: 10,
      price: 10,
      total: 100,
      itemPlatformId: 'V0ljTXZIZ0pUaGk2V1poclNaTElRZz09O0JFRURfMDY=',
      container: {
        name: 'Garrafa De Plastico 500ml',
        itemSize: 500,
        unitOfMeasurement: 'ML',
        returnable: true,
      },
      packageItem: {
        name: 'Un',
        itemCount: 1,
      },
    },
    {
      sku: '0004293',
      itemImage: 'https://cms-prod.global.ssl.fastly.net/media/09-05-2023-File-08d245dd.png',
      itemName: 'Brahma Duplo Malte 600ml - Unidade',
      quantity: 10,
      price: 10,
      total: 100,
      itemPlatformId: 'V0ljTXZIZ0pUaGk2V1poclNaTElRZz09O0JFRURfMDI=',
      container: {
        name: 'Garrafa De Ambar 600ml',
        itemSize: 600,
        unitOfMeasurement: 'ML',
        returnable: true,
      },
      packageItem: {
        name: 'Un',
        itemCount: 1,
      },
    },
    {
      sku: '0004293',
      itemImage: 'https://cms-prod.global.ssl.fastly.net/media/09-05-2023-File-08d245dd.png',
      itemName: 'Agua Mineral Ama 1x12 500ml',
      quantity: 10,
      price: 10,
      total: 100,
      itemPlatformId: 'V0ljTXZIZ0pUaGk2V1poclNaTElRZz09O0JFRURfMDU=',
      container: {
        name: 'Garrafa De Plastico 500ml',
        itemSize: 500,
        unitOfMeasurement: 'ML',
        returnable: false,
      },
      packageItem: {
        name: 'Cx',
        itemCount: 12,
      },
    },
    {
      sku: '0004293',
      itemImage: 'https://cms-prod.global.ssl.fastly.net/media/09-05-2023-File-08d245dd.png',
      itemName: 'Skol Pilsen Litrão 1l - Unidade',
      quantity: 10,
      price: 10,
      total: 100,
      itemPlatformId: 'V0ljTXZIZ0pUaGk2V1poclNaTElRZz09O0JFRURfMDE=',
      container: {
        name: 'Garrafa De Ambar 1l',
        itemSize: 1000,
        unitOfMeasurement: 'ML',
        returnable: true,
      },
      packageItem: {
        name: 'Un',
        itemCount: 1,
      },
    },
  ],
  serviceStatus: {
    value: DeliveryEnumStatus.DEFINITELY_RETURNED,
    description: 'Delivery declined.',
    color: 'red',
    timestamp: '2023-07-31T12:22:59.783Z',
    reason: 'There are no products available!',
  },
};

export const deliveryDetailsData: IDeliveryDetailsApi = {
  id: deliveryDetailsTempMock.id,
  distributionCenterId: deliveryDetailsTempMock.distributionCenter.id,
  accountId: deliveryDetailsTempMock.account.accountId,
  accountName: deliveryDetailsTempMock.account.displayName,
  accountAddress: deliveryDetailsTempMock.accountDeliveryAddress.address,
  accountCity: deliveryDetailsTempMock.accountDeliveryAddress.city,
  accountState: deliveryDetailsTempMock.accountDeliveryAddress.state,
  accountZipcode: deliveryDetailsTempMock.accountDeliveryAddress.zipcode || '',
  driverName: deliveryDetailsTempMock.driverName,
  truckPlate: deliveryDetailsTempMock.truckPlate,
  estimatedTimeOfArrival: deliveryDetailsTempMock.estimatedTimeOfArrival,
  finishedAt: 'some-finished-time',
  orderAmount: 100,
  progressBars: [
    {
      value: 100,
      color: 'red',
    },
    {
      value: 100,
      color: 'red',
    },
    {
      value: 100,
      color: 'red',
    },
  ],
  status: {
    value: DeliveryEnumStatus.DEFINITELY_RETURNED,
    description: 'Delivery declined.',
    color: 'red',
    timestamp: '2023-07-31T12:22:59.783Z',
    reason: 'There are no products available!',
  },
  updates: [
    {
      timestamp: '2023-07-31T12:22:59.783Z',
      value: DeliveryEnumStatus.DEFINITELY_RETURNED,
      description: 'Delivery declined.',
      color: 'red',
      reason: 'There are no products available!',
    },
    {
      timestamp: '2023-07-31T12:21:59.783Z',
      value: DeliveryEnumStatus.IN_TREATMENT,
      description: 'Something went wrong. Finding a solution.',
      color: 'red',
      reason: '',
    },
    {
      timestamp: '2023-07-31T12:20:59.783Z',
      value: DeliveryEnumStatus.WAITING_MODULATION,
      description: 'Something went wrong. Finding a solution.',
      color: 'red',
      reason: 'There are no products available',
    },
    {
      timestamp: '2023-07-31T12:19:59.783Z',
      value: DeliveryEnumStatus.DELIVERY_STARTED,
      description: 'Sr. Test is at the store.',
      color: 'green',
      reason: '',
    },
    {
      timestamp: '2023-07-31T12:18:59.783Z',
      value: DeliveryEnumStatus.ON_THE_WAY,
      description: 'Sr. Test is on the way.',
      color: 'green',
      reason: '',
    },
    {
      timestamp: '2023-07-31T12:17:59.783Z',
      value: DeliveryEnumStatus.NOT_STARTED,
      description: 'Delivery is planned for today.',
      color: 'green',
      reason: '',
    },
  ],
  products: deliveryDetailsTempMock.items,
};
