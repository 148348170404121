import { IDelivery } from '@api';
import { DeliveryEnumStatus } from 'enums/lastMile';

export const lastMileMockArray: IDelivery[] = [
  {
    id: '001',
    distributionCenterId: '1',
    accountId: '13485987000123',
    accountNamestatus: 'John Doe',
    estimatedTimeOfArrival: '2023-07-18T10:00:00Z',
    finishedAt: '2023-07-18T18:00:00Z',
    trackingCode: 'ABC123',
    driverName: 'Jane Smith',
    truckPlate: 'IJB321',
    accountName:
      'Lorem ipsum dolor sit amet. Vel recusandae itaque in recusandae quia aut alias dolore in rerum ullam sit quisquam quae.',
    progressBars: [
      {
        value: 0,
        color: '',
      },
      {
        value: 0,
        color: '',
      },
      {
        value: 0,
        color: '',
      },
    ],
    status: {
      value: DeliveryEnumStatus.NOT_STARTED,
      description: 'Delivery is planned for today.',
      color: 'green',
    },
  },
  {
    id: '002',
    distributionCenterId: '2',
    accountId: '00623904000343',
    accountNamestatus: 'John Doe',
    estimatedTimeOfArrival: '2023-07-18T15:33:00Z',
    finishedAt: '2023-07-18T18:00:00Z',
    trackingCode: 'ABC123',
    driverName: 'Jane Smith',
    truckPlate: 'XYZ456',
    accountName: 'BeesMart Sumaré',
    progressBars: [
      {
        value: 25,
        color: 'black',
      },
      {
        value: 0,
        color: '',
      },
      {
        value: 0,
        color: '',
      },
    ],
    status: {
      value: DeliveryEnumStatus.ON_THE_WAY,
      description: 'Mario is on the way.',
      color: 'red',
    },
  },
  {
    id: '003',
    distributionCenterId: '3',
    accountId: '03456110000150',
    accountNamestatus: 'John Doe',
    estimatedTimeOfArrival: '2023-07-18T17:20:00Z',
    finishedAt: '2023-07-18T18:00:00Z',
    trackingCode: 'ABC123',
    driverName: 'Jane Smith',
    truckPlate: 'XYZ456',
    accountName:
      'Vel eveniet ratione sit exercitationem enim sit nesciunt voluptatem At adipisci aliquam et officiis totam aut molestiae praesentium id quos earum.',
    progressBars: [
      {
        value: 10,
        color: 'green',
      },
      {
        value: 100,
        color: 'black',
      },
      {
        value: 0,
        color: '',
      },
    ],
    status: {
      value: DeliveryEnumStatus.DELIVERY_STARTED,
      description: 'Lucas is at the store.',
      color: 'red',
    },
  },
  {
    id: '004',
    distributionCenterId: '4',
    accountId: '00623904000343',
    accountNamestatus: 'John Doe',
    estimatedTimeOfArrival: '2023-07-18T08:00:00Z',
    finishedAt: '2023-07-18T18:00:00Z',
    trackingCode: 'ABC123',
    driverName: 'Jane Smith',
    truckPlate: 'XYZ456',
    accountName: 'BeesMart Sumaré',
    progressBars: [
      {
        value: 100,
        color: 'green',
      },
      {
        value: 100,
        color: 'green',
      },
      {
        value: 100,
        color: 'green',
      },
    ],
    status: {
      value: DeliveryEnumStatus.CONCLUDED,
      description: 'Delivery completed.',
      color: 'green',
    },
  },
  {
    id: '005',
    distributionCenterId: '5',
    accountId: '00623904000343',
    accountNamestatus: 'John Doe',
    estimatedTimeOfArrival: '2023-07-18T10:45:00Z',
    finishedAt: '2023-07-18T18:00:00Z',
    trackingCode: 'ABC123',
    driverName: 'Jane Smith',
    truckPlate: 'XYZ456',
    accountName: 'BeesMart Sumaré',
    progressBars: [
      {
        value: 10,
        color: 'green',
      },
      {
        value: 100,
        color: 'black',
      },
      {
        value: 0,
        color: '',
      },
    ],
    status: {
      value: DeliveryEnumStatus.WAITING_MODULATION,
      description: 'Something went wrong. Finding a solution.',
      color: 'red',
    },
  },
  {
    id: '006',
    distributionCenterId: '6',
    accountId: '00623904000343',
    accountNamestatus: 'John Doe',
    estimatedTimeOfArrival: '2023-07-18T07:30:00Z',
    finishedAt: '2023-07-18T18:00:00Z',
    trackingCode: 'ABC123',
    driverName: 'Jane Smith',
    truckPlate: 'XYZ456',
    accountName: 'BeesMart Sumaré',
    progressBars: [
      {
        value: 10,
        color: 'green',
      },
      {
        value: 100,
        color: 'black',
      },
      {
        value: 0,
        color: '',
      },
    ],
    status: {
      value: DeliveryEnumStatus.IN_TREATMENT,
      description: 'Something went wrong. Finding a solution.',
      color: 'red',
    },
  },
  {
    id: '007',
    distributionCenterId: '7',
    accountId: '00623904000343',
    accountNamestatus: 'John Doe',
    estimatedTimeOfArrival: '2023-07-18T13:47:00Z',
    finishedAt: '2023-07-18T18:00:00Z',
    trackingCode: 'ABC123',
    driverName: 'Jane Smith',
    truckPlate: 'XYZ456',
    accountName: 'BeesMart Sumaré',
    progressBars: [
      {
        value: 100,
        color: 'green',
      },
      {
        value: 100,
        color: 'green',
      },
      {
        value: 80,
        color: 'green',
      },
    ],
    status: {
      value: DeliveryEnumStatus.PARTIAL_DELIVERY,
      description: 'Partial delivery completed.',
      color: 'green',
    },
  },
  {
    id: '008',
    distributionCenterId: '8',
    accountId: '00623904000343',
    accountNamestatus: 'John Doe',
    estimatedTimeOfArrival: '2023-07-18T18:00:00Z',
    finishedAt: '2023-07-18T18:00:00Z',
    trackingCode: 'ABC123',
    driverName: 'Jane Smith',
    truckPlate: 'XYZ456',
    accountName: 'BeesMart Sumaré',
    progressBars: [
      {
        value: 100,
        color: 'red',
      },
      {
        value: 100,
        color: 'red',
      },
      {
        value: 100,
        color: 'red',
      },
    ],
    status: {
      value: DeliveryEnumStatus.DEFINITELY_RETURNED,
      description: 'Delivery declined.',
      color: 'red',
    },
  },
  {
    id: '009',
    distributionCenterId: '9',
    accountId: '00623904000343',
    accountNamestatus: 'John Doe',
    estimatedTimeOfArrival: '2023-07-18T14:15:00Z',
    finishedAt: '2023-07-18T18:00:00Z',
    trackingCode: 'ABC123',
    driverName: 'Jane Smith',
    truckPlate: 'XYZ456',
    accountName: 'BeesMart Sumaré',
    progressBars: [
      {
        value: 0,
        color: '',
      },
      {
        value: 0,
        color: '',
      },
      {
        value: 0,
        color: '',
      },
    ],
    status: {
      value: DeliveryEnumStatus.RESCHEDULED,
      description: 'Delivery rescheduled.',
      color: 'red',
    },
  },
  {
    id: '010',
    distributionCenterId: '10',
    accountId: '00623904000343',
    accountNamestatus: 'John Doe',
    estimatedTimeOfArrival: '2023-07-18T16:40:00Z',
    finishedAt: '2023-07-18T18:00:00Z',
    trackingCode: 'ABC123',
    driverName: 'Jane Smith',
    truckPlate: 'XYZ456',
    accountName: 'BeesMart Sumaré',
    progressBars: [
      {
        value: 100,
        color: 'green',
      },
      {
        value: 100,
        color: 'black',
      },
      {
        value: 0,
        color: 'red',
      },
    ],
    status: {
      value: DeliveryEnumStatus.RESCHEDULED,
      description: 'Rescheduled',
      color: 'green',
    },
  },
  {
    id: '011',
    distributionCenterId: '11',
    accountId: '00623904000343',
    accountNamestatus: 'John Doe',
    estimatedTimeOfArrival: '2023-07-18T16:40:00Z',
    finishedAt: '2023-07-18T18:00:00Z',
    trackingCode: 'ABC123',
    driverName: 'Jane Smith',
    truckPlate: 'XYZ456',
    accountName: 'BeesMart Sumaré',
    progressBars: [
      {
        value: 100,
        color: 'green',
      },
      {
        value: 100,
        color: 'black',
      },
      {
        value: 0,
        color: 'red',
      },
    ],
    status: {
      value: DeliveryEnumStatus.RESCHEDULED,
      description: 'Rescheduled',
      color: 'green',
    },
  },
  {
    id: '012',
    distributionCenterId: '12',
    accountId: '00623904000343',
    accountNamestatus: 'John Doe',
    estimatedTimeOfArrival: '2023-07-18T16:40:00Z',
    finishedAt: '2023-07-18T18:00:00Z',
    trackingCode: 'ABC123',
    driverName: 'Jane Smith',
    truckPlate: 'XYZ456',
    accountName: 'BeesMart Sumaré',
    progressBars: [
      {
        value: 100,
        color: 'green',
      },
      {
        value: 100,
        color: 'black',
      },
      {
        value: 0,
        color: 'red',
      },
    ],
    status: {
      value: DeliveryEnumStatus.RESCHEDULED,
      description: 'Rescheduled',
      color: 'green',
    },
  },
];
