import { PageLoader } from '@components';
import { LazyExoticComponent, ReactElement, Suspense, lazy } from 'react';

export type Modules =
  | 'COMMERCIAL_CONTROL'
  | 'DELIVERY_LIST'
  | 'DELIVERY_DETAILS'
  | 'ORDERS'
  | 'ORDER_DETAILS';

type Route = {
  path: string;
  Element: LazyExoticComponent<() => ReactElement>;
  children?: Route[];
};

type RoutesProps = Record<Modules, Route>;

export const CommercialControlPage = lazy(() => import('views/CommercialControl'));
export const DeliveryList = lazy(() => import('views/Deliveries'));
export const Orders = lazy(() => import('views/Orders'));
export const OrderDetails = lazy(() => import('views/OrderDetails'));
export const DeliveryDetails = lazy(() => import('views/DeliveryDetails'));

export const homePath = `/link-admin`;

export const commercialControlPath = `/commercial-control`;

export const deliveryListPath = `${commercialControlPath}/deliveries`;
export const deliveryDetailsPath = `${deliveryListPath}/deliveries-details/:accountId/:vendorId`;

export const ordersPath = `${commercialControlPath}/orders`;
export const orderDetailsPath = `${ordersPath}/order-details/:accountId/:orderNumber/:vendorId/:poNumber`;

export const Routes: RoutesProps = {
  COMMERCIAL_CONTROL: { path: commercialControlPath, Element: CommercialControlPage },
  DELIVERY_LIST: { path: deliveryListPath, Element: DeliveryList },
  DELIVERY_DETAILS: {
    path: `${deliveryDetailsPath}`,
    Element: DeliveryDetails,
  },
  ORDERS: { path: ordersPath, Element: Orders },
  ORDER_DETAILS: {
    path: orderDetailsPath,
    Element: OrderDetails,
  },
};

export const modules = [
  { path: '/', Element: () => null },
  Routes.COMMERCIAL_CONTROL,
  Routes.DELIVERY_LIST,
  Routes.DELIVERY_DETAILS,
  Routes.ORDERS,
  Routes.ORDER_DETAILS,
];

export const routesConfig = modules.map(({ path, Element }) => ({
  path,
  element: (
    <Suspense fallback={<PageLoader />}>
      <Element />
    </Suspense>
  ),
}));
