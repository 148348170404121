import { IGetChains } from 'api/OrdersList/useGetOrders.types';

export const chainsListMock: IGetChains[] = [
  {
    chain: {
      id: 'e54c2af0-0f5a-4151-9bb2-d34b64d96789',
      name: 'SEGUNAA',
    },
    vendorId: '58870cbc-7809-4e18-ba59-986b4992c842',
    accounts: [
      '09357685000364',
      '09345463000160',
      '09353578000104',
      '09353578000449',
      '09353578000520',
      '09353578000600',
      '09353578000791',
      '09353578000953',
      '09353868000140',
      '09353868000220',
      '09357685000100',
      '09357685000283',
    ],
  },
  {
    chain: {
      id: 'd05fd983-217e-42ee-ab6b-e90755fda9c2',
      name: 'QUARTA',
    },
    vendorId: '58870cbc-7809-4e18-ba59-986b4992c842',
    accounts: [
      '00063960010325',
      '00063960004350',
      '00063960008932',
      '00063960009076',
      '00063960009319',
      '00063960009661',
      '00063960009823',
      '00063960010244',
      '00063960011216',
      '00063960011305',
      '00063960011488',
      '00063960012107',
      '00063960012298',
      '00063960012530',
      '00063960012611',
      '00063960013006',
      '00063960013260',
      '00063960017338',
      '00063960019462',
      '00063960019624',
      '00063960022170',
      '00063960010678',
    ],
  },
];
