export interface IOrdersFilter {
  startDate: Date | null;
  endDate: Date | null;
  deliveryStartDate?: Date | null;
  deliveryEndDate?: Date | null;
  accountIds: string[] | null;
  orderNumber: string | null;
  poNumber: string | null;
  vendorOrderNumber: string | null;
  includeStatus: string[] | null;
  vendorIds: string[] | null;
}
export interface IGetOrders extends IOrdersFilter {
  orderStatus?: string[];
  orderRegions?: string[];
  restriction?: boolean;
  page: number;
  pageSize: number;

  needsAttention?: boolean;
  inTransit?: boolean;
}
export interface IGetOrderDetailsResponse {
  summary: {
    orderNumber: string;
    poNumber: string;
    orderDate: string;
    deliveryDate: string;
    totalAmount: number;
    account: {
      accountId: string;
      vendorAccountId: string;
      vendorId: string;
      name: string;
      address: string;
      city: string;
      state: string;
      zipcode?: string;
    };
    vendor: {
      id: string;
      accountId: string;
      orderNumber: string | null;
      displayName: string;
    };
  };
  details: {
    pagination: {
      page: number;
      pageSize: number;
      totalPages: number;
      totalElements: number;
    };
    items: {
      sku: string;
      ean: null;
      description: string;
      image: string | null;
      quantity: number;
      type: string;
      packageName: string;
      container: {
        name: string;
        itemSize: number;
        size: number;
        unitOfMeasurement: string;
        returnable: boolean;
      };
      summaryStatus: {
        RECEIVED: number;
        CANCELLED: number;
        DELIVERED: number;
        CONFIRMED: number;
        RETURNED: number;
        TOTAL_PLACED: number;
        ERROR: number;
      };
      package: {
        itemCount: null;
        name: string;
        pack: string | null;
        unitCount: number;
      };
    }[];
    totalStatus: {
      RECEIVED?: number;
      CANCELLED?: number;
      DELIVERED?: number;
      CONFIRMED?: number;
      RETURNED?: number;
      TOTAL_PLACED?: number;
      ERROR?: number;
    };
    empties: {
      quantity: number;
    };
    errorColumn: boolean;
  };
  microserviceResponseTime: number;
  requestTraceId: string;
}

export interface IGetChains {
  chain: {
    id: string;
    name: string;
  };
  vendorId: string;
  accounts: string[];
}

export interface IGetStores {
  chain: {
    id: string;
    name: string;
  };
  vendorId: string;
  accounts: {
    pocId: string;
    pocName: string;
  }[];
}

export type TIncludeStatus = keyof typeof EIncludeStatus;

export enum EIncludeStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  IN_TRANSIT = 'IN_TRANSIT',
  NEEDS_ATTENTION = 'NEEDS_ATTENTION',
}

export interface IGetOrdersResponse {
  content: IOrder[];
  pagination: IServicePagination;
  totals: IGetTotalsResponse;
  requestTraceId?: string;
}

export interface IOrder {
  orderNumber: string;
  poNumber: string;
  status: TOrderStatus;
  totalAmount: number;
  taxesAndDiscountsAmount: number;
  totalSkus: number;
  orderDate: string;
  deliveryDate?: string;
  numberOfRestrictions: number;
  totalItemQuantity: number;
  account?: IOrderAccount;
  subtotalAmount: number;
  needsAttention?: boolean;
  vendor?: IVendorOrder;
  deliveryRange: {
    minDays: number;
    maxDays: number;
  };
}

export interface IServicePagination {
  page: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
}

export interface IGetTotalsResponse {
  total: number;
  totalInProgress: number;
  totalNeedAttention: number;
  totalCancelled: number;
  totalCompleted: number;
  percentageInProgress: number;
  percentageNeedAttention: number;
  percentageCancelled: number;
  percentageCompleted: number;
}

export type TOrderStatus =
  | 'PENDING'
  | 'PLACED'
  | 'CONFIRMED'
  | 'DENIED'
  | 'MODIFIED'
  | 'IN_TRANSIT'
  | 'CANCELLED'
  | 'DELIVERED'
  | 'PARTIAL_DELIVERY'
  | 'INVOICED'
  | 'PENDING_CANCELLATION'
  | 'PENDING_PAYMENT';

export interface IOrderAccount {
  accountId: string;
  vendorAccountId: string;
  vendorId: string;
  name: string;
  address: string;
  city?: string;
  state?: string;
  zipcode?: string;
  chain?: {
    name: string;
  };
}

export interface IVendorOrder {
  id?: string;
  accountId?: string;
  orderNumber?: string;
  displayName?: string;
}

export interface IOrderDetails {
  accountId: string;
  orderNumber: string;
  poNumber: string | null;
  vendorId: string;
  page: number;
  pageSize: number;
}

export interface IOrderChain {
  accountId: string;
}

export const orderDetailsDefault: IGetOrderDetailsResponse = {
  summary: {
    orderNumber: '',
    poNumber: '',
    orderDate: '',
    deliveryDate: '',
    totalAmount: 0,
    account: {
      accountId: '',
      vendorAccountId: '',
      vendorId: '',
      name: '',
      address: '',
      city: '',
      state: '',
      zipcode: '',
    },
    vendor: {
      id: '',
      accountId: '',
      orderNumber: null,
      displayName: '',
    },
  },
  details: {
    pagination: {
      page: 0,
      pageSize: 0,
      totalPages: 0,
      totalElements: 0,
    },
    items: [
      {
        sku: '',
        ean: null,
        description: '',
        image: null,
        quantity: 0,
        type: '',
        packageName: '',
        container: {
          name: '',
          itemSize: 0,
          size: 0,
          unitOfMeasurement: '',
          returnable: false,
        },
        summaryStatus: {
          RECEIVED: 0,
          CANCELLED: 0,
          DELIVERED: 0,
          CONFIRMED: 0,
          RETURNED: 0,
          TOTAL_PLACED: 0,
          ERROR: 0,
        },
        package: {
          itemCount: null,
          name: '',
          pack: null,
          unitCount: 0,
        },
      },
    ],
    totalStatus: {
      RECEIVED: 0,
      CANCELLED: 0,
      DELIVERED: 0,
      CONFIRMED: 0,
      RETURNED: 0,
      TOTAL_PLACED: 0,
      ERROR: 0,
    },
    empties: {
      quantity: 0,
    },
    errorColumn: true,
  },
  microserviceResponseTime: 0,
  requestTraceId: '',
};

export const orderDefault: IGetOrdersResponse = {
  content: [
    {
      orderNumber: '',
      poNumber: '',
      status: 'PENDING',
      totalAmount: 0,
      taxesAndDiscountsAmount: 0,
      totalSkus: 0,
      orderDate: '',
      deliveryDate: '',
      numberOfRestrictions: 0,
      totalItemQuantity: 0,
      account: {
        accountId: '',
        vendorAccountId: '',
        vendorId: '',
        name: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        chain: {
          name: '',
        },
      },
      subtotalAmount: 0,
      needsAttention: false,
      vendor: {
        id: '',
        accountId: '',
        orderNumber: '',
        displayName: '',
      },
      deliveryRange: {
        minDays: 0,
        maxDays: 0,
      },
    },
  ],
  pagination: {
    page: 0,
    pageSize: 0,
    totalPages: 0,
    totalElements: 0,
  },
  totals: {
    total: 0,
    totalInProgress: 0,
    totalNeedAttention: 0,
    totalCancelled: 0,
    totalCompleted: 0,
    percentageInProgress: 0,
    percentageNeedAttention: 0,
    percentageCancelled: 0,
    percentageCompleted: 0,
  },
  requestTraceId: '',
};

export const orderChainDefault: IGetChains[] = [
  {
    chain: {
      id: '',
      name: '',
    },
    vendorId: '',
    accounts: [],
  },
];

export const orderStoresDefault: IGetStores[] = [
  {
    chain: {
      id: '',
      name: '',
    },
    vendorId: '',
    accounts: [
      {
        pocId: '',
        pocName: '',
      },
    ],
  },
];
